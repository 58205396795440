import React from "react"
import { graphql } from "gatsby"
import ZebraLayout from "../components/zebra-layout"
import Artwork from "../components/artwork"
import ArtworkAside from "../components/artwork-aside"

export const query = graphql`
  query($artworkID: String!) {
    artwork(id: { eq: $artworkID }) {
      name
      year
      dimensions
      description
      slug
      technique
      image {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
`
const ArtworkTemplate = (what) => {
  
  const { data: { artwork }, pageContext } = what
  
  const stuff = {...artwork, ...pageContext}
  
  const aside = <ArtworkAside {...stuff} />

  return (
  <ZebraLayout aside={aside}>
    <Artwork {...stuff} />
  </ZebraLayout>
)}
export default ArtworkTemplate