/** @jsx jsx */
import { jsx, Styled } from 'theme-ui'
import { Link } from 'gatsby';
import Img from "gatsby-image";
import { Box } from "rebass"

// Centering gatsby-image Img component is a bit tricky because it fits the width of the containing element.
// See https://github.com/gatsbyjs/gatsby/issues/14988

const Artwork = (options) => {
  const { name, dimensions, description, year, next, prev, technique, image } = options
  
  return (
    <Box>
      <Box>
        <Img fluid={image.childImageSharp.fluid} imgStyle={{ objectFit: 'contain' }}
          sx={{
            maxHeight: 'calc(100vh - 4rem)',
            margin: "2em"
          }}
        />
      </Box>
      <Box
        sx={{
          display: ["block", "none", "none"]
        }}>
        <Styled.h1>{name}</Styled.h1>
        <Styled.p>({year})</Styled.p>
        <Styled.p>{dimensions}</Styled.p>
        <Styled.p>{technique}</Styled.p>
        <Styled.p>{description}</Styled.p>
        <Styled.div>
          { prev && <Link to={prev}>PREV</Link> } { prev && next && "/" } { next && <Link to={next}>NEXT</Link> }
        </Styled.div>
      </Box>
    </Box>
)}
export default Artwork