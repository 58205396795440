/** @jsx jsx */
import { Styled, jsx } from 'theme-ui'
import { Link } from 'gatsby';

const ArtworkAside = (options) => {
  const { name, year, dimensions, description, next, prev } = options
  
  return (
  <aside>
    <Styled.h1>{name}</Styled.h1>
    <Styled.p>{dimensions}, {year}</Styled.p>
    <Styled.p>{description}</Styled.p>
    <Styled.div>
      { prev && <Link to={prev}>PREV</Link> } { prev && next && "/" } { next && <Link to={next}>NEXT</Link> }
    </Styled.div>
  </aside>
)}
export default ArtworkAside